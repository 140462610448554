// If you want to override variables do it here
@import 'variables';

// Import styles
@import '~@coreui/coreui/scss/coreui';
@import '~angular-notifier/styles.css';

// If you want to add something do it here
@import 'custom';

.navbar-brand {
  display: none !important;
}

.navbar-toggler:nth-of-type(3) {
  display: none !important;
}

.navbar-toggler:nth-of-type(4) {
  display: none !important;
}

.badge {
  padding: 8px;
}

.header-v-prod,
.header-v-test,
.header-v-legal,
.header-v-legal-test,
.header-v-nutaku {
  display: none;
}
.version-prod app-header {
  background-color: #edd9d6;
  .header-v-prod {
    display: inline;
  }
}

.version-test app-header {
  background-color: #cfe1cf;
  .header-v-test {
    display: inline;
  }
}

.version-nutaku app-header {
  background-color: #e53155;
  color: #fff;
  .header-v-nutaku {
    display: inline;
    color: #fff;
  }
}
.version-nutaku app-header {
  background-color: #e53155;
  color: #fff;
  .header-v-nutaku {
    display: inline;
    color: #fff;
  }
}
.version-legal app-header {
  background-color: #5b91d8;
  color: #fff;
  .header-v-legal {
    display: inline;
    color: #fff;
  }
}
.version-legal-test app-header {
  background-color: #81a5d5;
  color: #fff;
  .header-v-legal-test {
    display: inline;
    color: #fff;
  }
}

.coin-icon {
  width: 20px !important;
  height: 20px !important;
}

.go-to-admin-version-block {
  margin-right: 20px;
  a,
  a:visited {
    font-weight: 700;
    background-color: #fff;
    border-radius: 3px;
    padding: 3px;
    margin: 5px;
    &.go-to-nutaku {
      color: #e53155;
    }
    &.go-to-prod {
      color: rgb(255, 162, 162);
    }
    &.go-to-test {
      color: green;
    }
    &.go-to-legal {
      color: rgb(18, 124, 236);
    }
    &.go-to-legal-test {
      color: rgb(106, 158, 214);
    }
  }
}

// .modal-dialog {
//     min-width: 650px;
// }

.chat-id-field {
  background-color: rgb(225, 225, 225);
  color: #000;
  font-size: 12px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  position: relative;
  display: inline-block;
  max-width: 150px;
  .copy-icon {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 15px;
    height: 15px;
  }
  .copied {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(44, 167, 44);
    color: #fff;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    opacity: 1;
    animation: foo 2s ease-in-out;
  }

  @keyframes foo {
    100% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
  }
}

.color-green {
  color: rgb(46, 187, 46);
}
.color-red {
  color: rgb(216, 37, 37);
}

.type-indicator {
  background: grey;
  color: #fff;
  padding: 2px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);

  &.real {
    background-color: rgb(16, 198, 180);
  }
  &.free {
    background-color: rgb(63, 241, 18);
  }
  &.tutorial {
    background-color: rgb(241, 215, 18);
  }
  &.trial {
    background-color: rgb(204, 18, 241);
  }

  &.none {
    background-color: rgb(107, 99, 255);
  }

  &.time {
    background-color: rgb(241, 122, 18);
  }

  &.count {
    background-color: rgb(18, 241, 234);
  }
}

.btn-small {
  font-size: 12px;
  padding: 5px;
}

.btn-tooltip {
  position: relative;
}
.btn-tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #bcbcbc;
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 32px;
  right: 0px;
}

.btn-tooltip:hover .tooltiptext {
  visibility: visible;
}

.btn.disabled {
  background-color: #ccc;
  border-color: #ccc;
}

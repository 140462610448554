// Here you can add other styles
.show {
    display: block !important;
}

.upper-case {
    text-transform: uppercase !important;
}

.pointer {
    cursor: pointer;
}

.logout {
    margin-right: 30px !important;
}

.cdk-drop-list-dragging .cdk-drag {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
    padding: 4px;

    tr {
        th {
            vertical-align: middle;
            max-width: 25px;
        }
        td {
            position: relative;
            vertical-align: middle;
            max-width: 100px;
            min-width: 100px;
        }
    }

    img {
        width: auto;
        height: 50px;
    }

    .img-container {
        position: relative;
        width: fit-content;

        .edit-avatar {
            position: absolute;
            top: -10px;
            right: -10px;
            width: 20px;
            height: 20px;
        }
    }
}
.cdk-drag-placeholder {
    opacity: 0;
}

::ng-deep .cdk-drag-preview .mat-list-item-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
    position: relative;
    height: inherit;
}
